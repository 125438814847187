
<script setup lang="ts">
import WebApp from '@twa-dev/sdk'
import { useMiniappStore } from '~~/stores/miniapp';

const colorMode = useColorMode()
console.log(colorMode.preference)

const miniAppStore = useMiniappStore()
const { isModalVisible, toggle, toggleBubbleVisibility, popoutChatWindow }
    = useChatWoot()


onMounted(() => {
  WebApp.ready();

  toggleBubbleVisibility('hide')

  WebApp.MainButton.hide();
  WebApp.BackButton.hide();
  WebApp.setHeaderColor('#000000');
  WebApp.expand();
  WebApp.onEvent('activated', () => {
    toggleBubbleVisibility('hide')
  })
  WebApp.onEvent('deactivated', () => {
    toggleBubbleVisibility('show')
  })
  WebApp.onEvent('mainButtonClicked', () => {
    popoutChatWindow()
  })
  WebApp.onEvent('backButtonClicked', () => {
    WebApp.close()
  })
  WebApp.onEvent('themeChanged', () => {
    if (WebApp.colorScheme === 'dark') {
      colorMode.preference = 'dark'
    } else {
      colorMode.preference = 'light'
    }
  })
  WebApp.setHeaderColor('#000000')
  WebApp.setBackgroundColor('#000000');
  console.log(WebApp.initDataUnsafe)
  if (WebApp?.initDataUnsafe?.user){
    miniAppStore.setInitData(WebApp.initDataUnsafe)
    miniAppStore.setSupported(true)
  }
  watch(() => WebApp.initDataUnsafe, (newValue) => {
  console.log('watcher')
  console.log(newValue)
})
})

const color = computed(() =>
  colorMode.value === 'dark' ? '#111827' : 'white',
)

useHead({
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { key: 'theme-color', name: 'theme-color', content: color },
  ],
  link: [
    { rel: 'icon', href: '/favicon.ico' },
    { rel: 'apple-touch-icon', href: '/apple-touch-icon-180x180.png' },
  ],

  htmlAttrs: {
    lang: 'ru',
  },
})

useSeoMeta({})
</script>

<template>
  <NuxtPwaAssets />
  <div class="hidden lg:block gradient min-h-page" />
  <NuxtLoadingIndicator />

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <UModals />
  <UNotifications />
</template>

<style>
.gradient {
    position: absolute;
    inset: 0;
    pointer-events: none;
    background: radial-gradient(
        50% 50% at 50% 50%,
        rgb(var(--color-primary-500) / 0.25) 0,
        #fff 100%
    );
}

.dark {
    .gradient {
        background: radial-gradient(
            50% 50% at 50% 50%,
            rgb(var(--color-primary-400) / 0.1) 0,
            rgb(var(--color-gray-950)) 100%
        );
    }
}

.overlay {
    background-size: 100px 100px;
    background-image: linear-gradient(
            to right,
            rgb(var(--color-gray-200)) 0.5px,
            transparent 0.5px
        ),
        linear-gradient(
            to bottom,
            rgb(var(--color-gray-200)) 0.5px,
            transparent 0.5px
        );
}
.dark {
    .overlay {
        background-image: linear-gradient(
                to right,
                rgb(var(--color-gray-900)) 0.5px,
                transparent 0.5px
            ),
            linear-gradient(
                to bottom,
                rgb(var(--color-gray-900)) 0.5px,
                transparent 0.5px
            );
    }
}
</style>
