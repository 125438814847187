import { buildPathUrl, collect, config, logger } from "#build/umami.config.mjs";
import { earlyPromise, flattenObject, isValidString } from "./utils.js";
let configChecks;
let staticPayload;
let queryRef;
function runPreflight() {
  if (typeof window === "undefined")
    return "ssr";
  if (window.localStorage.getItem("umami.disabled") === "1")
    return "local-storage";
  if (configChecks)
    return configChecks;
  configChecks = function() {
    const { ignoreLocalhost, domains } = config;
    const hostname = window.location.hostname;
    if (ignoreLocalhost && hostname === "localhost")
      return "localhost";
    if (domains && !domains.includes(hostname))
      return "domain";
    return true;
  }();
  return configChecks;
}
;
function getStaticPayload() {
  if (staticPayload)
    return staticPayload;
  const {
    location: { hostname },
    screen: { width, height },
    navigator: { language }
  } = window;
  const { tag } = config;
  staticPayload = {
    hostname,
    language,
    screen: `${width}x${height}`,
    ...tag ? { tag } : null
  };
  return staticPayload;
}
function getQueryRef() {
  if (typeof queryRef === "string")
    return queryRef;
  const params = new URL(window.location.href).searchParams;
  queryRef = params.get("referrer") || params.get("ref") || "";
  return queryRef;
}
function getPayload() {
  const { referrer, title } = window.document;
  const ref = referrer || getQueryRef();
  const url = buildPathUrl();
  const tag = window.localStorage.getItem("umami.tag");
  return {
    ...getStaticPayload(),
    ...tag ? { tag } : null,
    url: encodeURI(url),
    referrer: encodeURI(ref),
    title: encodeURIComponent(title)
  };
}
;
function umTrackView(url, referrer) {
  const check = runPreflight();
  if (check === "ssr")
    return earlyPromise(false);
  if (check !== true) {
    logger(check);
    return earlyPromise(false);
  }
  return collect({
    type: "event",
    payload: {
      ...getPayload(),
      ...isValidString(referrer) && { referrer: encodeURI(referrer) },
      ...isValidString(url) && { url: encodeURI(url) }
    }
  });
}
function umTrackEvent(eventName, eventData) {
  const check = runPreflight();
  if (check === "ssr")
    return earlyPromise(false);
  if (check !== true) {
    logger(check);
    return earlyPromise(false);
  }
  const data = flattenObject(eventData);
  let name = eventName;
  if (!isValidString(eventName)) {
    logger("event-name");
    name = "#unknown-event";
  }
  return collect({
    type: "event",
    payload: {
      name,
      ...getPayload(),
      ...data && { data }
    }
  });
}
function umIdentify(sessionData) {
  const check = runPreflight();
  if (check === "ssr")
    return earlyPromise(false);
  if (check !== true) {
    logger(check);
    return earlyPromise(false);
  }
  const data = flattenObject(sessionData);
  return collect({
    type: "identify",
    payload: {
      ...getPayload(),
      ...data && { data }
    }
  });
}
function umTrackRevenue(eventName, revenue, currency = "USD") {
  const $rev = typeof revenue === "number" ? revenue : Number(revenue);
  if (Number.isNaN($rev) || !Number.isFinite(revenue)) {
    logger("revenue", revenue);
    return earlyPromise(false);
  }
  let $cur = null;
  if (typeof currency === "string" && /^[A-Z]{3}$/i.test(currency.trim()))
    $cur = currency.trim();
  else
    logger("currency", `Got: ${currency}`);
  return umTrackEvent(eventName, {
    revenue: $rev,
    ...$cur ? { currency: $cur } : null
  });
}
export { umIdentify, umTrackEvent, umTrackRevenue, umTrackView };
