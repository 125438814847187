import { defineStore } from 'pinia'
import WebApp from '@twa-dev/sdk'

// Extracting the type of 'initDataUnsafe' from the return type of 'useWebApp'
type InitDataType = typeof WebApp.initDataUnsafe // Replace 'any' with the actual type of 'initDataUnsafe'

export const useMiniappStore = defineStore('MiniappStore', () => {
  // State
  const supported = ref(false)
  const initData = ref<InitDataType>({} as InitDataType)

  // Actions
  const setSupported = (value: boolean) => {
    supported.value = value
  }

  const setInitData = (data: InitDataType) => {
    initData.value = data
  }

  // Optional Getters (if needed)
  const isSupported = computed(() => supported.value)

  return {
    // State
    supported,
    initData,
    // Actions
    setSupported,
    setInitData,
    // Getters (if any)
    isSupported,
  }
})
