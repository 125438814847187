import { useSiteConfig } from "#site-config/app/composables/useSiteConfig";
import { defineWebPage, defineWebSite } from "@unhead/schema-org/vue";
import { defineNuxtPlugin } from "nuxt/app";
import { useSchemaOrg } from "../composables/useSchemaOrg.js";
import { maybeAddIdentitySchemaOrg } from "../utils/shared.js";
export default defineNuxtPlugin({
  name: "nuxt-schema-org:defaults",
  dependsOn: [
    "nuxt-schema-org:init"
  ],
  setup() {
    const siteConfig = useSiteConfig();
    useSchemaOrg([
      defineWebSite({
        name: siteConfig?.name || "",
        inLanguage: siteConfig?.currentLocale || "",
        description: siteConfig?.description || ""
      }),
      defineWebPage()
    ]);
    maybeAddIdentitySchemaOrg();
  }
});
