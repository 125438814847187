import { useHead, useServerHead } from "@unhead/vue";
import { useSchemaOrgConfig } from "../utils/config.js";
export function useSchemaOrg(input) {
  const config = useSchemaOrgConfig();
  const script = {
    type: "application/ld+json",
    key: "schema-org-graph",
    nodes: input,
    tagPriority: "high",
    ...config.scriptAttributes
  };
  if (import.meta.dev) {
    return useHead({
      script: [script]
    });
  }
  if (import.meta.server) {
    return useServerHead({
      script: [script]
    });
  } else if (config?.reactive) {
    return useHead({
      script: [script]
    });
  }
}
